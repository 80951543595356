import React from 'react';
import { StaticQuery, graphql } from 'gatsby';
import { globalHistory } from '@reach/router';
import IndexTemplate from '../templates/IndexTemplate';
import SEO from '../components/seo';
import HomeContent from '../components/HomeContent';

// TODO: we should replace the video query below with custom graphql resolvers,
// to only query the nodes we need for the 4-card "Featured" and "Newest Videos"
// on-demand sections. This would likely create two custom queries - one for
// newestVideos and one for featuredVideos, which would significantly decrease
// the homepage's payload size

const homeVideosQuery = graphql`
  query homeVideosQuery {
    newest: allTrailheadLiveVideo(
      sort: { fields: datetime, order: DESC }
      filter: { vidyardId: { ne: "" }, featured: { ne: true } }
      limit: 4
    ) {
      nodes {
        ...VideosFragment
      }
      totalCount
    }
    featured: allTrailheadLiveVideo(
      sort: { fields: datetime, order: DESC }
      filter: { vidyardId: { ne: "" }, featured: { eq: true } }
      limit: 4
    ) {
      nodes {
        ...VideosFragment
      }
      totalCount
    }
    site {
      siteMetadata {
        pages {
          home {
            slug
          }
        }
      }
    }
  }
`;

const Index = () => (
  <StaticQuery
    query={homeVideosQuery}
    render={data => {
      const { location } = globalHistory;
      const featured = {
        videos: [...data.featured.nodes],
        totalCount: data.featured.totalCount
      };
      const newest = {
        videos: [...data.newest.nodes],
        totalCount: data.newest.totalCount
      };
      const onDemand = { featured, newest };
      // const validVideos = data.allTrailheadLiveVideo.nodes.filter(validItems);
      return (
        <IndexTemplate>
          {/* Canonical URL must include trailing slash because of Heroku redirects */}
          <SEO
            slug={data.site.siteMetadata.pages.home.slug}
            canonicalPathname="/"
          />
          <HomeContent onDemand={onDemand} location={location} />
        </IndexTemplate>
      );
    }}
  />
);

export default Index;
