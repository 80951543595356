import React from 'react';
import PropTypes from 'prop-types';
import { Link } from '../CustomLink';
import withLocation from '../withLocation';
import OnDemandCard from '../OnDemandCard';

const OnDemandSection = ({ items: { featured, newest } }) => {
  const sections = [
    {
      title: 'Featured',
      viewAllLink: '/videos/?type=featured',
      items: featured.videos,
      showViewAllBtn: featured.videos.length < featured.totalCount
    },
    {
      title: 'Newest videos',
      viewAllLink: '/videos/?type=all',
      items: newest.videos,
      showViewAllBtn: newest.videos.length < newest.totalCount
    } /* ,
    {
      title: "Trending videos",
      viewAllLink: "/videos/?type=trending",
      items: trendingEls,
      showViewAllBtn: (trendingTotalCount > trendingEls.length)
    } */
  ];

  return (
    <>
      {sections.map((section, index) => {
        if (section.items.length > 0) {
          const { items: videos } = section;
          const elements = videos.map((video, i) => (
            <div
              key={video.id}
              className="slds-col slds-size_1-of-1 slds-medium-size_1-of-2"
            >
              <OnDemandCard index={i} item={video} />
            </div>
          ));

          const sectionClass = index > 0 ? 'slds-p-top--small' : '';
          return (
            <section className="tlv-on-demand__section" key={section.title}>
              <div className={`tlv-page__section-heading ${sectionClass}`}>
                <h2 className="tlv-page__heading">{section.title}</h2>
                {section.showViewAllBtn && (
                  <Link
                    to={section.viewAllLink}
                    className="slds-button slds-button_neutral"
                  >
                    View All
                  </Link>
                )}
              </div>
              <div className="slds-grid slds-gutters slds-wrap slds-grid_vertical-stretch">
                {elements}
              </div>
            </section>
          );
        }
        return null;
      })}
    </>
  );
};

OnDemandSection.propTypes = {
  items: PropTypes.shape({
    newest: PropTypes.shape({
      videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      totalCount: PropTypes.number.isRequired
    }).isRequired,
    featured: PropTypes.shape({
      videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      totalCount: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};

export default withLocation(OnDemandSection);
