import React from 'react';
import PropTypes from 'prop-types';

import HeroCard from '../HeroCard';

const HomeHero = React.memo(({ isLive, videoData }) => (
  <section className="tlv-home-hero">
    <div className="tlv-home-hero__inner">
      <div className="tlv-home-hero__content">
        <div className="tlv-home-hero__text">
          <div className="tlv-home-hero__supertitle">Welcome to</div>
          <h1 className="tlv-home-hero__title">Trailhead Live</h1>
          <div className="tlv-home-hero__subtitle">
            Watch live and on-demand videos from experts on today&#39;s most
            in-demand skills.
          </div>
        </div>
        <div className="tlv-home-hero__card">
          <HeroCard isLive={isLive} videoData={videoData} />
        </div>
      </div>
    </div>
  </section>
));

HomeHero.defaultProps = {
  isLive: null
};

HomeHero.propTypes = {
  isLive: PropTypes.bool,
  videoData: PropTypes.shape({
    type: PropTypes.string,
    video: PropTypes.shape({})
  }).isRequired
};

export default HomeHero;
