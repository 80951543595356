import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import HomeHero from '../HomeHero';
import { LiveScheduleHeading, LiveScheduleContent } from '../LiveSchedule';
import OnDemandSection from '../OnDemandSection';
import BroadcastingContext from '../../contexts/BroadcastingContext';
import VideosContext from '../../contexts/VideosContext';
import { VIDEO_STATUS } from '../../constants/videoStatus';

const HomeContent = ({ onDemand }) => {
  const { broadcasting, iframe, setVideoInPlayer } = useContext(
    BroadcastingContext
  );
  const { current, upcoming } = useContext(VideosContext);
  useEffect(() => {
    setVideoInPlayer(current);
  }, [setVideoInPlayer, current]);
  const heroVideoData = () => {
    if (current[0]) return { video: current[0], type: VIDEO_STATUS.CURRENT };
    if (upcoming[0]) return { video: upcoming[0], type: VIDEO_STATUS.UPCOMING };
    return { video: onDemand.newest.videos[0], type: VIDEO_STATUS.ON_DEMAND };
  };

  return (
    <div className="tlv-page">
      <HomeHero videoData={heroVideoData()} isLive={broadcasting} />
      <div className="tlv-page__content-primary">
        <div className="tlv-page__content-primary-container">
          <div className="tlv-home-content">
            <div className="tlv-home-content__inner slds-grid slds-gutters_direct">
              <section className="tlv-home-content__live-schedule slds-col">
                <LiveScheduleHeading
                  showViewAllButton={!!current.length || !!upcoming.length}
                />
                <LiveScheduleContent
                  currentVideos={current}
                  upcomingVideos={upcoming}
                  groupByDays="NEXT_7_DAYS"
                />
              </section>
              <div className="tlv-home-content__on-demand slds-col">
                <OnDemandSection items={onDemand} />
              </div>
            </div>
          </div>
        </div>
      </div>
      {iframe}
    </div>
  );
};

export default HomeContent;

HomeContent.propTypes = {
  onDemand: PropTypes.shape({
    newest: PropTypes.shape({
      videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      totalCount: PropTypes.number.isRequired
    }).isRequired,
    featured: PropTypes.shape({
      videos: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
      totalCount: PropTypes.number.isRequired
    }).isRequired
  }).isRequired
};
