import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Link } from '../CustomLink';
import DatetimeUtils from '../../utils/DatetimeUtils';
import SessionUtils from '../../utils/SessionUtils';
import LiveBadge from '../LiveBadge';
import VideosContext from '../../contexts/VideosContext';
import Speaker from '../Speaker';
import AuthContext from '../../contexts/AuthContext';
import CalendarEvent from '../CalendarEvent';
import GatedContentIndicator from '../GatedContentIndicator';
import { VIDEO_STATUS } from '../../constants/videoStatus';
import isContentGated from '../../utils/isContentGated';
import fallbackHeroImg from '../../assets/images/default-video-thumbnail.png';

const HeroCard = React.memo(({ isLive, videoData }) => {
  const { CURRENT, ON_DEMAND, UPCOMING } = VIDEO_STATUS;
  const { ready } = useContext(VideosContext);
  const { isLoggedIn } = useContext(AuthContext);
  const { video = {}, type } = videoData;
  const startedDate =
    video.datetime && DatetimeUtils.getLocalizedDate(video.datetime);
  const startedTime =
    video.datetime && DatetimeUtils.getLocalizedTime(video.datetime);
  const heroMetadata = {
    [ON_DEMAND]: {
      linkTo: `/videos/${video.slug}`,
      relativeTimeText: `Recorded on`,
      timeValue: startedDate,
      gatedMessage: `Login required`
    },
    [CURRENT]: {
      linkTo: `/broadcasts/${video.slug}`,
      relativeTimeText: isLive ? `Started at ` : ``,
      timeValue: isLive ? startedTime : `${startedDate}, ${startedTime}`,
      gatedMessage: `Login required`
    },
    [UPCOMING]: {
      linkTo: `/broadcasts/${video.slug}`,
      relativeTimeText: ``,
      timeValue: `${startedDate}, ${startedTime}`,
      gatedMessage: `Login required`
    }
  };

  const { linkTo = '', relativeTimeText = '', timeValue = '' } = heroMetadata[
    type
  ];

  if (!ready) {
    return (
      <div className="tlv-card-home-hero slds-card tds-card">
        <div className="H(100%) tlv-slds-spinner-padding">
          <div className="slds-spinner_container">
            <div
              role="status"
              className="slds-spinner slds-spinner_medium slds-spinner_brand"
            >
              <span className="slds-assistive-text">Loading</span>
              <div className="slds-spinner__dot-a" />
              <div className="slds-spinner__dot-b" />
            </div>
          </div>
        </div>
      </div>
    );
  }

  // TODO: while we will rarely hit this case, it's worth improving the design here
  const emptyVideo = !Object.keys(video).length;
  if (emptyVideo) {
    return (
      <div className="tlv-card-home-hero slds-card tds-card slds-align_absolute-center">
        <p className="slds-p-around--x-large">
          No on-demand or current video to show.
        </p>
      </div>
    );
  }
  const {
    imageUrl: speakerImage,
    speakerName,
    speakerTitle,
    speakerTBIDProfile
  } = video.speakers[0];

  return (
    <article className="tlv-card-home-hero slds-card tds-card">
      <div className="tlv-card-home-hero__content">
        <div className="tlv-card-home-hero__left">
          <Link to={linkTo} className="u-display-block u-widescreen-media">
            <picture className="u-widescreen-media__inner">
              <img
                src={video.thumbnailUrl || fallbackHeroImg}
                alt="Video Thumbnail"
                className="u-widescreen-media__image"
              />
            </picture>
          </Link>
          {!isLoggedIn && isContentGated({ video, status: type }) && (
            <div className="tlv-card-home-hero__gated">
              <GatedContentIndicator
                labelText={heroMetadata[type].gatedMessage}
              />
            </div>
          )}
        </div>
        <div className="tlv-card-home-hero__right">
          <div className="tlv-card-home-hero__status slds-grid">
            {type === CURRENT && isLive && (
              <div className="tlv-card-home-hero__badge">
                <LiveBadge className="slds-m-right_medium" />
              </div>
            )}
            <div
              className="tlv-card-home-hero__date"
              data-testid="home-hero-date"
            >
              {relativeTimeText} <b>{timeValue}</b>
            </div>
            <CalendarEvent
              hide={type !== UPCOMING}
              item={video}
              dropdownClassName="tlv-dropdown--right"
            />
          </div>
          <div className="tlv-card-home-hero__title">
            <Link to={linkTo}>{video.sessionTitle}</Link>
          </div>
          <div
            className="tlv-card-home-hero__speaker"
            data-testid="home-hero-speaker"
          >
            <Speaker
              imageUrl={speakerImage}
              speakerName={speakerName}
              speakerSubtext={speakerTitle}
              speakerTBIDProfile={speakerTBIDProfile}
            />
            <div
              className="tlv-card-home-hero__duration"
              data-testid="home-hero-duration"
            >
              ~{SessionUtils.getLengthOfSession(video.lengthOfSession)}
            </div>
          </div>
        </div>
      </div>
    </article>
  );
});

HeroCard.defaultProps = {
  isLive: null
};
HeroCard.propTypes = {
  videoData: PropTypes.shape({
    video: PropTypes.shape({
      lengthOfSession: PropTypes.string.isRequired,
      sessionTitle: PropTypes.string.isRequired,
      thumbnailUrl: PropTypes.string,
      speakers: PropTypes.arrayOf(
        PropTypes.shape({
          imageUrl: PropTypes.string.isRequired,
          speakerName: PropTypes.string.isRequired,
          speakerTitle: PropTypes.string.isRequired,
          speakerTBIDProfile: PropTypes.string
        })
      ),
      datetime: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired
    }),
    type: PropTypes.string.isRequired
  }),

  isLive: PropTypes.bool
};

export default HeroCard;
